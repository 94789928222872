import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication/autnentication-service';
import { GlobalService } from '../admin/GlobalService/global-service';
import { AppLoaderServiceMessaging } from '../Shared/app-loader-service-messaging';
import { helper } from '../Shared/helper';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-samlauthentication',
  templateUrl: './samlauthentication.component.html',
  styleUrls: ['./samlauthentication.component.css']
})
export class SamlauthenticationComponent implements OnInit {
  _env: any;
  IsLoading: boolean;
  user = { username: "", password: "", org_id: 0 }
  userroleList: any = [];
  helper = new helper();
  internal_code: any;
  role_id: any = "0";
  _environment: any;
  org_code: any = "BEML";
  org_details: any;
  org_List: any;
  private http: HttpClient

  constructor(private auth: AuthenticationService, private global: GlobalService,
    private activated:ActivatedRoute,
    private _router: Router,
    private _loader_service: AppLoaderServiceMessaging) {


  }
  async ngOnInit() {
    this.org_code=this.activated.snapshot.paramMap.get('org_code');
    
    setTimeout(() => {
      // this.login();
    }, 500);
    //   this.login();
  }

  async login() {
    // debugger;
    // this._loader_service.show_loader();

    $(".preloader").fadeOut();
    let valid = true;

   
    if(this.user.username.trim()=='')
    {
      this.global.utilities.notify.error('Please enter Enrollment No', 'Error');
      return;
    }
    if(this.user.password.trim()=='')
    {
      this.global.utilities.notify.error('Please enter Password', 'Error');
      return;
    }
    if (valid === true) {
      const user: any = {
        Enrollment_No: this.user.username,
        Password: this.user.password,
        Advertisement:1
      }
      this.IsLoading = true;
      var param: any = {};
  
      this.helper.spName = "usp_get_admit_card";
      this.helper.payload = JSON.stringify(user);
      this.global.ServiceManager.request.post('Global/GetDataFromServer', this.helper).subscribe(res => {
        this.global.utilities.HideLoder();
        if (res.status == 1) {
         
          if (res.data.dataset.table1.length > 0) {
            
            this.global.utilities.storage.set('Enrollment_No', this.user.username);
            this.global.utilities.storage.set('Password', this.user.password);
            sessionStorage.setItem("isLogin", "1");
           this._router.navigate(['/app/candidate-details']);
          }
          else {
            this.IsLoading = false;
            // this.global.utilities.notify.error('Invalid Enrollment No Or DOB.');
            this.global.utilities.notify.ShowAlert('Please Enter valid Registration No and Password', '')
            return;
          }
  
        }
  
  
      }
        , err => {
          
          this.global.utilities.notify.error('Something went wrong. Try again.', '');
          this.IsLoading = false;
        }
      )
  
  
      console.log(user)
    }
  }

  
  BindOrgDetails()
  {
    this.org_details =this.org_List.filter(x=>x.org_id== this.user.org_id)[0];
  }
}
